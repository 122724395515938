import {ref, onMounted} from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import {api} from '@/services/api'
import router from "@/router";
import orderStatusTransport from "@/fake-db/orderStatusTransport.json"

export default function useCoverSheet() {
    const textSearch = ref('')

    const headers = ref([
        {
            text: 'เลขออเดอร์',
            value: 'order_code',
            width: '140',
        },
        {
            text: 'ลูกค้า',
            value: 'order_transportation_name',
            width: '200',
        },
        {
            text: 'ผู้ทำรายการ',
            value: 'user_create',
            width: '150',
        },
        {
            text: 'วันที่สร้างออเดอร์',
            value: 'order_create',
            width: '160',
        },
        {
            text: 'จำนวนเงิน',
            value: 'order_total',
            width: '160',
            align: 'end',
        },
        {
            text: 'สถานะจัดส่ง',
            value: 'order_transportation_status',
            width: '170',
            align: 'center',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: 100,
            align: 'center fixed',
        },
    ])

    const itemsPerPage = ref(10)

    const dataList = ref([])

    const loading = ref(false)

    const isUpdateStatus = ref(false)

    const dataEdit = ref({})

    const footer = ref(footerDataTables)

    const isEdit = ref(false)

    const isAdd = ref(false)

    const isPrint = ref(false)

    const selectedRows = ref([])

    const orderStatusTransportSelection = ref('')

    const status = ref('1')

    const options = ref([{
        text: 'ทั้งหมด',
        value: '',
    },
        {
            text: 'ร้านค้า',
            value: '2',
        },
        {
            text: 'Data center',
            value: '1',
        }
    ])

    const selection = ref('')

    const isShowDetail = ref(false)

    const idList = ref([])

    const changeStatus = (id) => {
        status.value = id
        getProducts()
    }

    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/printTransportation',
            param: `?order_transportation_status=${orderStatusTransportSelection.value}&currency_id=${localStorage.getItem('currencyId')}`
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    const langSelection = ref(2)

    const confirmPrint = () => {
        let data = {
            id: String(idList.value),
            lang: langSelection === 1 ? 'th' : 'ko'
        }
        if (langSelection.value === 1) {
            data.lang = 'th'
            const routeData = router.resolve(
                {
                    name: 'print-cover-th',
                    query: data,
                })
            window.open(routeData.href, '_blank')
        } else {
            data.lang = 'ko'
            const routeData = router.resolve(
                {
                    name: 'print-cover-ko',
                    query: data,
                })
            window.open(routeData.href, '_blank')
        }
    }

    const sendPrint = id => {
        if (id) {
            idList.value = [id]
        } else {
            idList.value = selectedRows.value.map(item => item.order_id)
        }
        isPrint.value = true
    }

    onMounted(() => {
        getProducts(idList.value)
    })


    return {
        isPrint,
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        selection,
        options,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        selectedRows,
        orderStatusTransportSelection,
        langSelection,
        sendPrint,
        confirmPrint,
        changeStatus,
        getProducts,
    }
}
