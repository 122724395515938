<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>
          ใบแปะหน้ากล่อง
        </span>
        <v-spacer></v-spacer>
        <v-btn color='primary' :disabled="!selectedRows.length" @click="sendPrint(null)">พิมพ์ใบแปะหน้า</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getProducts'
                        label='ค้นหาสินค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <v-select filled label='สถานะการจัดส่ง' :items='orderStatusTransport'
                    dense
                    @click="getProducts"
                    hide-details
                    item-text="label"
                    item-value="value"
                    v-model='orderStatusTransportSelection'
                    @change='getProducts'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          v-model="selectedRows"
          show-select
          item-key="order_id"
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.order_transportation_status`]='{ item }'>
          <StatusOrderTransport :status='String(item.order_transportation_status)'/>
        </template>
        <template #[`item.order_transportation_status`]='{ item }'>
          <StatusOrderTransport :status='String(item.order_transportation_status)'/>
        </template>
        <template #[`item.order_total`]='{ item }'>
          {{ item.order_total | formatPrice }} <CurrentCurrency />
        </template>
        <template #[`item.order_create`]='{ item }'>
          {{ item.order_create | sumdatetime }}
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  @click="sendPrint(item.order_id)"
                  v-bind='attrs'
                  v-on='on'
              >
                <v-icon>{{ mdiPrinterCheck }}</v-icon>
              </v-btn>
            </template>
            <span>พิพม์ใบแปะหน้า</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="isPrint" persistent max-width="300">
      <v-card>
        <v-card-title>
          เลือกภาษา
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-0">
          <v-radio-group v-model="langSelection" hide-details>
            <v-radio
                label="한국"
                :value="2"
            ></v-radio>
            <v-radio
                label="ไทย"
                :value="1"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmPrint">พิมพ์</v-btn>
          <v-btn color="secondary" outlined @click="isPrint = false">ยกเลิก</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'
import {mdiPrinterCheck} from '@mdi/js'
import useCoverSheet from "./useCoverSheet";
import StatusOrderTransport from "@/components/StatusOrderTransport";
import {sumdatetime, formatPrice} from "@/services/filters";
import orderStatusTransport from "@/fake-db/orderStatusTransport.json"
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
  props: {},
  components: {
    CurrentCurrency,
    FilterStatus,
    StatusBlock,
    StatusOrderTransport,
    orderStatusTransport,
  },
  filters: {
    sumdatetime, formatPrice
  },
  setup(_, {root, emit}) {
    return {
      ...useCoverSheet(),
      mdiPrinterCheck,
      orderStatusTransport,
    }
  },

}
</script>

<style scoped>

</style>
